/** @jsx jsx */
import { Link } from "gatsby"
import { Button, jsx, Link as TLink } from "theme-ui"

const LinkButton = ({ to, externalLink, children }) =>
  externalLink !== undefined ? (
    <a href={externalLink} target="_blank" rel="noopener noreferrer">
      <Button>{children}</Button>
    </a>
  ) : (
    <TLink as={Link} to={to}>
      <Button>{children}</Button>
    </TLink>
  )

export default LinkButton
