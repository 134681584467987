/** @jsx jsx */
import { Box, Heading, jsx, Styled, Text } from "theme-ui"
import Layout from "./layout"
import LinkButton from "./link-button"
import SEO from "./seo"

const ConsultingPage = props => (
  <Layout>
    <SEO title="Consulting" />
    <Heading variant="styles.h2" mb={4}>
      Consulting
    </Heading>
    <Text variant="styles.p">
      Schedule a 1 on 1 with me to solve your Machine Learning problem
    </Text>
    <br />
    <Text variant="styles.p">I can help you in the following areas:</Text>
    <Styled.ul variant="styles.ul">
      <li>Classical Machine Learning</li>
      <li>General Deep Learning</li>
      <li>Natural Language Processing</li>
      <li>Computer Vision</li>
      <li>Time Series</li>
      <li>
        Model deployment (Docker & Kubernetes, REST APIs, mobile, IOT, edge
        devices)
      </li>
      <li>
        Tabular data (making predictions with data from SQL/NoSQL databases)
      </li>
      <li>Structure/organization of your Machine Learning project</li>
    </Styled.ul>
    <Text variant="styles.p">
      <span role="img" aria-label="credit card" sx={{ mr: 2 }}>
        💳
      </span>
      <strong>$199</strong> (USD)/2 hours
    </Text>

    <Styled.ul variant="styles.ul">
      <li>
        Shorter sessions (up to 2 hours) are{" "}
        <strong>charged as a 2-hour session</strong>
      </li>
      <li>
        <strong>Prepayment is required</strong> after confirmation
      </li>
      <li>
        <strong>There are no guarantees</strong> that we’ll solve your problem
      </li>
    </Styled.ul>
    <Box sx={{ textAlign: "center" }} pt={4}>
      <LinkButton externalLink={"https://bit.ly/venelin-consulting"}>
        SCHEDULE SESSION
      </LinkButton>
    </Box>
  </Layout>
)

export default ConsultingPage
